/* eslint-disable react-hooks/exhaustive-deps */
import Keycloak, {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakLogoutOptions,
} from 'keycloak-js';
import { ReactNode, createContext, useEffect, useState } from 'react';

import { useEnvironment } from '@cccom/config/env';

import AuthLoading from './AuthLoading';

export type CCKeycloak = {
  keycloak: Keycloak;
  initialized: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  parsedToken: {
    email: string;
    name: string;
    realmRoles: string[];
    userId: string;
    acr: ACRValue;
  };
};

export type ACRValue = 'standard' | 'esig';

export type ACRFlowParams = {
  keycloak: Keycloak;
  acrValue: ACRValue;
  prompt: 'none' | 'login';
};

type CCKeycloakProviderProps = { children: ReactNode };

async function useGetKeycloakConfig(): Promise<KeycloakConfig | undefined> {
  const env = useEnvironment();
  const config: Partial<KeycloakConfig> = {
    url: env.KEYCLOAK_ENDPOINT,
  };

  try {
    const response = await fetch(`${env.BACKEND_ENDPOINT}/iam/keycloak/config`);
    const responseConfig = await response.json();
    // TODO change client in DB and remove once merged to main
    return { ...responseConfig, ...config };
  } catch (err) {
    console.error('error fetching config:', err);
    return undefined;
  }
}

export const HAS_REDIRECTED = 'HAS_REDIRECTED';

/**
 * For extra log out logic
 */
export function onLogout(
  keycloak: Keycloak,
  options?: KeycloakLogoutOptions | undefined
) {
  localStorage.removeItem(HAS_REDIRECTED);
  return keycloak.logout(options);
}

function useInitKeycloak() {
  const env = useEnvironment();
  const keycloakConfig = useGetKeycloakConfig();
  const [kcState, setKCState] = useState<CCKeycloak>({
    keycloak: new Keycloak(),
    initialized: false,
    isAuthenticated: false,
    isLoading: true,
    parsedToken: {
      email: '',
      name: '',
      realmRoles: [],
      userId: '',
      acr: 'standard',
    },
  });

  useEffect(() => {
    const controller = new AbortController();

    const initialize = async () => {
      const config = await keycloakConfig;
      const kc = new Keycloak(config);

      const updateKCState = () => {
        const { tokenParsed, idToken, token } = kc;

        setKCState({
          ...kcState,
          keycloak: kc,
          initialized: true,
          isLoading: false,
          isAuthenticated: !!idToken && !!token,
          parsedToken: {
            email: tokenParsed?.email,
            name: tokenParsed?.name,
            realmRoles: tokenParsed?.realm_access?.roles ?? [],
            userId: tokenParsed?.sub ?? '', // userId in Keycloak
            acr: (tokenParsed?.acr as ACRValue) ?? 'standard',
          },
        });
      };

      const refreshTokenIfExpired = async () => {
        if (document.visibilityState === 'visible' && kc.isTokenExpired()) {
          try {
            const result = await kc.updateToken(-1);
            if (result) updateKCState();
          } catch (error) {
            console.error('Auth Error: failed to refresh token', error);
            onLogout(kc);
          }
        }
      };

      document.addEventListener('visibilitychange', refreshTokenIfExpired, {
        signal: controller.signal,
      });
      kc.onTokenExpired = refreshTokenIfExpired;

      try {
        const initOptions: KeycloakInitOptions = {
          onLoad: 'login-required',
          checkLoginIframe: false,
          enableLogging: !env.production,
        };

        const authenticated = await kc.init(initOptions);
        // Force the user to login if not authenticated.
        if (!authenticated) await kc.login();

        updateKCState();
      } catch (err) {
        console.error('error initializing:', err);
        kc.logout();
      }
    };

    initialize();

    return () => {
      document.removeEventListener('visibilitychange', () =>
        controller.abort()
      );
    };
  }, []);

  return kcState;
}

export function startACRFlow({ keycloak, acrValue, prompt }: ACRFlowParams) {
  const currentUserEmail = keycloak.tokenParsed?.email;

  keycloak.login({
    prompt,
    loginHint: currentUserEmail,
    acr: { values: [acrValue], essential: true },
  });
}

export const KeycloakContext = createContext<CCKeycloak>({
  keycloak: new Keycloak(),
  initialized: false,
  isAuthenticated: false,
  isLoading: true,
  parsedToken: {
    email: '',
    name: '',
    realmRoles: [],
    userId: '',
    acr: 'standard',
  },
});

export function CCKeycloakProvider({ children }: CCKeycloakProviderProps) {
  const kcState = useInitKeycloak();
  const { initialized, isLoading } = kcState;

  if (!initialized || isLoading) return <AuthLoading />;

  return (
    <KeycloakContext.Provider value={kcState}>
      {children}
    </KeycloakContext.Provider>
  );
}
