import { APPS } from '@cccom/config/app-config';
import { EnvironmentType } from '@cccom/config/env';

export const environment: EnvironmentType = {
  production: import.meta.env.MODE === 'production',
  BACKEND_ENDPOINT: import.meta.env.VITE_BACKEND_ENDPOINT,
  KEYCLOAK_ENDPOINT: import.meta.env.VITE_KEYCLOAK_ENDPOINT,
  FARO_ENDPOINT: import.meta.env.VITE_FARO_ENDPOINT,
  APP: APPS.HOME,
};
