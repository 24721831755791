import { Flex, Image, Text } from '@chakra-ui/react';

export type CCLogoProps = {
  logoSrc: string;
  appName: string;
};

export function CCLogo(props: CCLogoProps) {
  const { logoSrc, appName } = props;
  return (
    <Flex px="2" alignItems="center" gap="2">
      <Image src={logoSrc} alt="logo" height="10" />
      {appName && (
        <Text textTransform="capitalize" color="blue.950">
          {appName}
        </Text>
      )}
    </Flex>
  );
}
