import { Permissions } from '@cccom/auth/permissions-type';

const APP_FAMILY_NAMES = {
  VIEW: 'VIEW',
  QI: 'QI',
  SOLUTIONS: 'SOLUTIONS',
};

export const APP_IDS = {
  HOME: 'HOME',
  QI: 'QI',
  ATREVIEW: 'ATREVIEW',
  SYSTEM_INVENTORY: 'SYSTEM_INVENTORY',
  ELOGBOOK: 'ELOGBOOK',
  UNAUTHORIZED: 'UNAUTHORIZED',
} as const;

type ObjectValues<T> = T[keyof T];

export type AppStatus = 'ACTIVE' | 'FUTURE';
export type AppFamilyNames = ObjectValues<typeof APP_FAMILY_NAMES>;
export type AppIds = ObjectValues<typeof APP_IDS>;

export type AppMenu = {
  [APP_IDS.HOME]: undefined;
  [APP_IDS.QI]: 'environments' | 'groups' | 'activity-log';
  [APP_IDS.ATREVIEW]:
    | 'parameter-changes'
    | 'systems'
    | 'inclusions'
    | 'non-attributable-users'
    | 'activity-log';
  [APP_IDS.SYSTEM_INVENTORY]: 'systems' | 'activity-log';
  [APP_IDS.ELOGBOOK]: 'logbooks';
  [APP_IDS.UNAUTHORIZED]: undefined;
};

export type App = {
  id: AppIds;
  name: string;
  status: AppStatus;
  logo: string;
  path: string; // for serving on docker or prod
  port: string; // for serving locally
  theme: Record<string, any>;
  errorLogo: string;
  homePage: string;
  appFamily: AppFamilyNames;
  scopes: Permissions[];
  description?: string;
  lastAccessed?: boolean;
  helpCenter?: string;
};
