import Keycloak, { KeycloakLogoutOptions } from 'keycloak-js';
import { useContext, useEffect } from 'react';

import { HAS_REDIRECTED, KeycloakContext, onLogout } from './keycloak';

function useKeycloak() {
  const { keycloak, initialized, isAuthenticated, isLoading, parsedToken } =
    useContext(KeycloakContext);

  useEffect(() => {
    const { isTokenExpired } = keycloak;
    if (isTokenExpired()) {
      localStorage.removeItem(HAS_REDIRECTED);
    }
  }, [keycloak]);

  const logout = (options?: KeycloakLogoutOptions | undefined) =>
    onLogout(keycloak, options);

  return {
    initialized,
    isAuthenticated,
    isLoading,
    ...parsedToken,
    keycloak: { ...keycloak, logout } as Keycloak,
  };
}

export default useKeycloak;
