import {
  Box,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CgMenuGridR } from 'react-icons/cg';
import { TbBulb } from 'react-icons/tb';

import { APPS } from '@cccom/config/app-config';
import { App } from '@cccom/config/app-type';
import { useEnvironment } from '@cccom/config/env';

const menuItemProps: MenuItemProps = {
  as: 'a',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  gap: '4',
  px: '7',
  py: '4',
  _hover: { textDecoration: 'none' },
};

export type AppSwitcherProps = {
  appList: App[];
};

export function AppSwitcher({ appList }: AppSwitcherProps) {
  const env = useEnvironment();
  const [t] = useTranslation();

  const activeApps = appList
    .map((app) => APPS[app?.id])
    .filter((app) => app?.status !== 'FUTURE');

  return (
    <Menu>
      <MenuButton aria-label="Switch Apps" data-cy="switchApp">
        <Icon
          as={CgMenuGridR}
          color="rgba(0,0,0,0.6)"
          boxSize="7"
          display="flex"
          align-items="center"
          margin-left="-3px"
        />
      </MenuButton>

      <MenuList display="grid" mt="1.5" color="gray.600">
        {activeApps.map((app) => (
          <MenuItem
            position="relative"
            key={app.id}
            href={APPS.getUrl(env, app)}
            data-cy={`switchTo${app.name}`}
            {...menuItemProps}
          >
            <Image src={app.logo} alt="logo" width="7" />
            <Text>{app.name}</Text>
          </MenuItem>
        ))}

        <MenuDivider />

        <MenuItem href={APPS.getUrl(env, APPS.HOME)} {...menuItemProps}>
          <Icon as={TbBulb} boxSize="7" />
          <Text>{t('home.menu.more_solutions')}</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
