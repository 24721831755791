// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastId, UseToastOptions } from '@chakra-ui/toast';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { i18n } from '@cccom/shared/i18n';
import { arrayToObject, toastAttributes } from '@cccom/shared/utils/common';

import { ApiError } from '../types';

export const tokenInterceptor = (
  config: InternalAxiosRequestConfig,
  token?: string
): InternalAxiosRequestConfig => {
  const apiConfig = config;
  if (apiConfig.headers && token) {
    apiConfig.headers.Authorization = `Bearer ${token}`;
  }
  return apiConfig;
};

export const apiErrorHandler = (
  error: AxiosError<ApiError>,
  toast: (options?: UseToastOptions) => ToastId
) => {
  if (!error.response) return;

  const showToastHandler = (toastDescription: string) => {
    toast({
      ...toastAttributes,
      id: Date.now().toString(),
      description: toastDescription,
      status: 'error',
    });
  };

  const i18nMessageHandler = ({
    i18nMessage,
    message,
  }: Pick<ApiError, 'i18nMessage' | 'message'>) => {
    if (!i18nMessage) return message;

    const { variables, key } = i18nMessage;

    if (!variables)
      return i18n.t([key, message ?? 'common.errors.something_went_wrong']);

    return i18n.t(
      [key, message ?? 'common.errors.something_went_wrong'],
      arrayToObject(variables)
    );
  };

  const apiError = error.response.data;
  if (!apiError || !apiError.message) {
    showToastHandler(i18n.t('common.errors.something_went_wrong'));
    return;
  }

  if (apiError.errors?.length) return;

  if (apiError.i18nMessage) {
    showToastHandler(i18nMessageHandler(apiError));
    return;
  }

  showToastHandler(apiError.message);
};
