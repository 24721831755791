import { format } from 'date-fns';

export const formatDate = (date: string, dateFormat: string) =>
  format(new Date(date), dateFormat);

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const arrayToObject = (arr: string[]) =>
  Object.fromEntries(arr.map((arrItem, index) => [index, arrItem]));
