import { extendTheme } from '@chakra-ui/react';
import baseTheme from '../../base-theme';

const theme = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme['colors'],
    brand: {
      ...baseTheme['colors'].green,
      topbar: baseTheme['colors'].green['400'],
      25: baseTheme['colors'].green['50'],
    },
  },
});

export default theme;
