import { App } from '@cccom/config/app-type';
import { EnvironmentType } from '@cccom/config/env';
import { i18n } from '@cccom/shared/i18n';
import {
  elogbookTheme,
  homeTheme,
  qiTheme,
  systemInventoryTheme,
  viewTheme,
} from '@cccom/shared/themes';

const HOME: App = {
  id: 'HOME',
  name: 'HOME',
  status: 'ACTIVE',
  logo: 'images/CClogo.svg',
  path: '/',
  port: ':3000',
  theme: homeTheme,
  errorLogo: 'images/cc-404-graphic.svg',
  homePage: '/',
  appFamily: 'Solutions',
  scopes: ['CC_USER'],
  helpCenter: 'HOME',
};

const QI: App = {
  id: 'QI',
  name: 'QI',
  status: 'ACTIVE',
  logo: 'images/qi/logo.svg',
  path: '/qi',
  port: ':3001',
  theme: qiTheme,
  errorLogo: 'images/qi/404-graphic.svg',
  homePage: '/qi',
  appFamily: 'QI',
  scopes: ['QI_USER'],
  description: i18n.t('qi.description'),
  helpCenter: 'QI',
};

const ATREVIEW: App = {
  id: 'ATREVIEW',
  name: 'ATReview',
  status: 'ACTIVE',
  logo: 'images/view/logo.svg',
  path: '/view/atreview',
  port: ':3002',
  theme: viewTheme,
  errorLogo: 'images/view/404-graphic.svg',
  homePage: '/view/atreview',
  appFamily: 'VIEW',
  scopes: ['ATREVIEW_USER'],
  description: i18n.t('atreview.atreview.description'),
  helpCenter: 'ATREVIEW',
};

const SYSTEM_INVENTORY: App = {
  id: 'SYSTEM_INVENTORY',
  name: 'System Inventory',
  status: 'ACTIVE',
  logo: 'images/system-inventory/logo.svg',
  path: '/view/system-inventory',
  port: ':3003',
  theme: systemInventoryTheme,
  errorLogo: 'images/system-inventory/cc-404-graphic.svg',
  homePage: '/view/system-inventory',
  appFamily: 'VIEW',
  scopes: ['SYSTEM_INVENTORY_USER'],
  description: i18n.t('system_inventory.description'),
  helpCenter: 'ATREVIEW',
};

const ELOGBOOK: App = {
  id: 'ELOGBOOK',
  name: 'eLogbook',
  status: 'ACTIVE',
  logo: 'images/elogbook/logo.svg',
  path: '/view/elogbook',
  port: ':3004',
  theme: elogbookTheme,
  errorLogo: 'images/elogbook/404-graphic.svg',
  homePage: '/view/elogbook',
  appFamily: 'VIEW',
  scopes: ['ELOGBOOK_USER'],
  description: i18n.t('atreview.atreview.description'),
  helpCenter: 'ATREVIEW',
};

const UNAUTHORIZED: App = {
  id: 'UNAUTHORIZED',
  name: 'UNAUTHORIZED',
  status: 'ACTIVE',
  logo: 'images/logo.svg',
  path: '/unauthorized',
  port: ':402',
  theme: homeTheme,
  errorLogo: 'images/unauthorized.svg',
  homePage: '/home',
  appFamily: 'Solutions',
  scopes: [],
};

const DefaultAppList = [ATREVIEW, QI, SYSTEM_INVENTORY, ELOGBOOK];

const getUrl = (env: EnvironmentType, app: App) => {
  const { protocol, hostname } = window.location;
  const appPath = env.production ? app.homePage : app.port;
  return `${protocol}//${hostname}${appPath}`;
};

const APPS = {
  HOME,
  QI,
  ATREVIEW,
  SYSTEM_INVENTORY,
  ELOGBOOK,
  UNAUTHORIZED,
  DefaultAppList,
  getUrl,
};

export default APPS;
