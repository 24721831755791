import { Box, Flex, HStack } from '@chakra-ui/react';

import { useSettings } from '@cccom/shared/settings';

import { AppSwitcher } from '../app-switcher/AppSwitcher';
import { CCLogo } from '../logo/CCLogo';

export type TopbarProps = {
  logoSrc: string;
  appName: string;
  onClickLogo: VoidFunction;
};

export function Topbar({ logoSrc, appName, onClickLogo }: TopbarProps) {
  const { appList } = useSettings();

  return (
    <Box
      position="fixed"
      top="0"
      as="section"
      width="100vw"
      borderTop="5px"
      backgroundColor="white"
      borderTopColor="brand.topbar"
      borderBottom="0.5px"
      borderBottomColor="gray.200"
      borderStyle="solid"
      zIndex="10"
    >
      <Flex justify="space-between" as="nav" bg="bg-surface" py="2" px="7">
        <HStack spacing="4" margin="0">
          <AppSwitcher appList={appList} />
          <Box cursor="pointer" onClick={onClickLogo}>
            <CCLogo logoSrc={logoSrc} appName={appName} />
          </Box>
        </HStack>
      </Flex>
    </Box>
  );
}
